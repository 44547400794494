.footer-container {
    background-color: #0F123F !important;
    color: #fff !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    padding: 30px 5% !important;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}

.footer-title {
    font-size: 1.5rem !important;
    font-weight: 700 !important;
    letter-spacing: 2px !important;
    margin-bottom: 10px !important;
}

.footer-link {
    color: #fff !important;
    text-decoration: none !important;
}

.footer-copyright-container {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: flex-end !important;
}

.footer-copyright-text {
    font-weight: 600 !important;
    letter-spacing: 2px !important;
}