.admin-side-button {
    color: #fff !important;
    font-size: 20px !important;
    width: 100% !important;
    font-weight: 700 !important;
    padding: 0 60px 0 30px !important;
    margin: 12px 0 12px 0 !important;
    line-height: 2rem !important;
}

.admin-side-button-active {
    color: #ffa800 !important;
    font-size: 20px !important;
    width: 100% !important;
    font-weight: 700 !important;
    padding: 0 60px 0 30px !important;
    margin: 12px 0 12px 0 !important;
    border-right: 6px solid #ffa800 !important;
    line-height: 2rem !important;
}

.admin-side-report-button {
    color: #fff !important;
    font-size: 20px !important;
    width: 100% !important;
    font-weight: 700 !important;
    padding: 0 10px 0 30px !important;
    margin: 12px 0 12px 0 !important;
}

.admin-side-report-button-active {
    color: #ffa800 !important;
    font-size: 20px !important;
    width: 100% !important;
    font-weight: 700 !important;
    padding: 0 10px 0 30px !important;
    margin: 12px 0 12px 0 !important;
    border-right: 6px solid #ffa800 !important;
}

.admin-report-button-title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.admin-main-title {
    font-weight: 700 !important;
    letter-spacing: 3px !important;
    user-select: none !important;
    cursor: pointer !important;
}