.leaflet-container {
    height: 100vh !important;
}

@media (max-width: 899px) {
    .leaflet-container {
        height: 50vh !important;
    }
}

.leaflet-popup-content {
    text-align: center !important;
    font-size: 18px !important;
    font-weight: bold !important;
}