.header-brand-title {
    font-size: 42px !important;
    letter-spacing: 2px !important;
    font-weight: 800 !important;
    cursor: pointer !important;
    width: fit-content !important;
}

.header-button-container {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
    align-content: center !important;
    flex-wrap: wrap !important;
}

.header-login-button-container {
    cursor: pointer;
    align-self: center;
    height: 1.85rem;
}

.header-admin-button-container {
    color: #000 !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
}