.message-container {
    border-radius: 12px;
    color: #000;
    padding: 6px 12px;
    font-weight: 700;
}

.message-okay {
    background-color: #bfeabf;
}

.message-error {
    background-color: rgba(239,102,102,0.67);
}