.drawer-button {
    font-size: 20px !important;
    width: 100% !important;
    font-weight: 700 !important;
    color: #0F123F !important;
    padding: 0 60px 0 30px !important;
    margin: 12px 0 12px 0 !important;
    line-height: 2rem !important;
}

.drawer-button-active {
    font-size: 20px !important;
    width: 100% !important;
    font-weight: 700 !important;
    color: #0F123F !important;
    padding: 0 60px 0 30px !important;
    margin: 12px 0 12px 0 !important;
    line-height: 2rem !important;
    border-right: 6px solid #0F123F !important;
}

.side-menu-title {
    text-align: center !important;
    font-weight: 700 !important;
}

.side-menu-table-header-cell {
    color: white !important;
    font-weight: 700 !important;
    font-size: 20px !important;
}

.side-menu-table-cell {
    color: white !important;
    font-size: 16px !important;
}

.drawer-report-button {
    font-size: 20px !important;
    width: 100% !important;
    font-weight: 700 !important;
    color: #0F123F !important;
    padding: 0 10px 0 30px !important;
    margin: 12px 0 12px 0 !important;
}

.drawer-report-button-title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.station-predictions-container {
    height: 100% !important;
    padding: 12px 12px !important;
}

.station-predictions-station-name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
}

.station-predictions-pm-values, .station-predictions-status-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}

.station-predictions-pm-values-title, .station-predictions-status-title {
    font-weight: 700;
}

.station-predictions-status-value {
    padding: 4px 8px;
    border-radius: 12px;
}