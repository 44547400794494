/*

    ADMIN MODEL DASHBOARD

 */

.admin-station-menu-item {
    color: #0F123F !important;
}

.admin-model-table-header-cell {
    font-weight: 800 !important;
    text-transform: uppercase !important;
    letter-spacing: 2px !important;
    font-size: 1.1rem !important;
}

.admin-models-management-button {
    border: 1px solid !important;
    border-radius: 8px !important;
}

.model-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.model-alias-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.model-alias-container-text {
    padding: 4px 8px;
    background-color: #ffa800;
    color: black;
    width: fit-content;
    border-radius: 12px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
}

.warning-message-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    padding: 15px;
    border: 2px solid #ffa800;
    width: 100%;
    border-radius: 12px;
    background-color: #fff6e6;
}

.model-status-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.model-status-container-active {
    color: rgb(0, 120, 103);
    font-weight: 700;
    border-radius: 12px;
    width: fit-content;
    padding: 4px 8px;
    background-color: rgba(0, 167, 111, 0.16);
}

.model-status-container-inactive {
    color: rgb(183, 29, 24);
    font-weight: 700;
    border-radius: 12px;
    width: fit-content;
    padding: 4px 8px;
    background-color: rgba(255, 86, 48, 0.16);
}

.editing-model-alias-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.editing-model-alias-name {
    display: flex;
    flex-direction: row;
    border: 2px solid #0F123F;
    padding: 4px 8px;
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    background-color: #0F123F;
    color: #fff;
}


/*

    USER MANAGEMENT DASHBOARD

 */

.admin-users-table-header-cell {
    font-weight: 700 !important;
    letter-spacing: 2px !important;
    font-size: 1rem !important;
}

.user-management-dashboard-container {
    display: flex !important;
    flex-direction: row !important;
    max-height: 100% !important;
    overflow-y: scroll !important;
    background-color: #F7F7F7 !important;
    align-self: flex-start !important;
}