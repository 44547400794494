.leaflet-container {
    /*height: 90.5vh !important;*/
    max-height: 100% !important;
}

@media (max-width: 899px) {
    .leaflet-container {
        height: 90vh !important;
    }
    .leaflet-control-attribution {
        display: none;
    }
}

.leaflet-popup-content {
    text-align: center !important;
    font-size: 18px !important;
    font-weight: bold !important;
}

.station-card-container {
    position: absolute;
    z-index: 1000;
    left: 5%;
    top: 50%;
    transform: translate(-5%, -50%);
}


/*

    PREDICTED VALUES CARD

*/
.predicted-values-card-container {
    margin-top: 5px !important;
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
    padding: 11px !important;
    border: 1px solid #E2E2E2 !important;
    border-radius: 12px !important;
}

.predicted-values-card-type {
    font-weight: 800 !important;
    font-size: 0.75rem !important;
}

.predicted-values-card-value {
    font-size: 1.5rem !important;
    font-weight: 800 !important;
    letter-spacing: 2px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.predicted-values-card-unit {
    font-size: 0.75rem !important;
    font-weight: 800 !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    color: #545454 !important;
}


/*

    FORECAST PREDICTIONS CARD

*/
.forecast-predictions-card-title {
    font-size: 1.5rem !important;
    font-weight: 800 !important;
    letter-spacing: 2px !important;
    margin-bottom: 15px !important;
}

.forecast-predictions-card-subtitle {
    font-size: 0.75rem !important;
    font-weight: 700 !important;
    letter-spacing: 2px !important;
    margin-bottom: 5px !important;
}


/*

    CURRENT PREDICTIONS CARD

 */
.current-predictions-card-title {
    font-size: 1.5rem !important;
    font-weight: 800 !important;
    letter-spacing: 2px !important;
}

.current-predictions-card-subtitle {
    font-size: 1.25rem !important;
    font-weight: 800 !important;
    letter-spacing: 2px !important;
}

.current-predictions-card-aqi-container {
    margin-top: 5px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 11px 0 !important;
    border: 1px solid #E2E2E2 !important;
    border-radius: 12px !important;
}

.current-predictions-card-aqi-text {
    font-size: 0.75rem !important;
    font-weight: 800 !important;
    margin-top: 5px !important;
    border-radius: 12px !important;
    color: #000 !important;
    letter-spacing: 2px !important;
}