.predictions-button {
    background-color: #0F123F !important;
    border-radius: 12px !important;
    color: #fff !important;
    font-weight: 700 !important;
    margin-top: 30px !important;
    padding: 6px 12px !important;
    border: 3px solid #0F123F !important;
    font-size: 1rem !important;
}

.predictions-button:hover {
    background-color: transparent !important;
    color: #000 !important;
}

.title-with-description-title {
    text-align: start !important;
    font-weight: 800 !important;
    font-size: 2.25rem !important;
}

.title-with-description-description {
    text-align: start !important;
    font-weight: 500 !important;
    font-size: 1.25rem !important;
    margin-top: 15px !important;
}


/*

    AQI INDEX INFORMATION ITEM

 */
.aqi-index-information-item-container {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}

.aqi-index-information-item-text {
    display: flex !important;
    color: #fff !important;
    border-radius: 12px !important;
    padding: 4px 8px !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
    flex-direction: column !important;
    z-index: 5 !important;
    font-weight: 600 !important;
}

.aqi-index-information-item-values-span {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 4px 8px !important;
    border-radius: 12px !important;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    padding-left: 15px !important;
    margin-left: -10px !important;
    z-index: 3 !important;
    background-color: #fff !important;
    font-weight: 600 !important;
}


/*

    AQI INDEX INFORMATION CONTAINER

 */
.aqi-index-information-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    border: 1px solid #E2E2E2;
    border-radius: 12px;
    background-color: #eeeeee;
    z-index: -3;
}