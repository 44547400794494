.login-submit-button {
    background-color: #0F123F !important;
    border-radius: 12px !important;
    color: #fff !important;
    font-weight: 800 !important;
    margin-top: 30px !important;
    padding: 6px 12px !important;
    border: 3px solid #0F123F !important;
}

.login-submit-button:hover {
    background-color: #fff !important;
    color: #000 !important;
}